<template>
    <v-container>
       <ComponentUploadDropdown/>
       <ComponentListDropdown/>
       <ComponentListDropdownSalesperson/>
       <ComponentListDropdownSalesfunnel/>
       <ComponentListDropdownLead/>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentUploadDropdown from '../../components/dropdown/ComponentUploadDropdown.vue';
import ComponentListDropdown from '../../components/dropdown/ComponentListDropdown.vue';
import ComponentListDropdownSalesperson from '../../components/dropdown/ComponentListDropdownSalesperson.vue';
import ComponentListDropdownSalesfunnel from '../../components/dropdown/ComponentListDropdownSalesfunnel.vue';
import ComponentListDropdownLead from '../../components/dropdown/ComponentListDropdownLead.vue';
export default {
    components:{
    ComponentUploadDropdown,
    ComponentListDropdown,
    ComponentListDropdownSalesperson,
    ComponentListDropdownSalesfunnel,
    ComponentListDropdownLead,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        }
    }
}
</script>